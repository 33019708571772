import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CreateUser, UpdateUser } from '@user/shared/models';

export const userDetailsActions = createActionGroup({
  source: 'User Details',
  events: {
    'Update User Profile': props<{
      updateUser: UpdateUser;
      notification: string | false;
      redirectUrl?: string;
    }>(),
    'Update User': props<{
      updateUser: UpdateUser;
      notification: string | false;
      redirectUrl?: string;
    }>(),
    'Create User': props<{
      createUser: CreateUser;
      notification: string | false;
      redirectUrl?: string;
    }>(),
    'Reset Password': props<{ email: string; notification: string }>(),
    'Init User Details Page': emptyProps(),
  },
});
