import { Category, ContentCategory, EventCategory } from '@mkp/tracking/feature-tealium';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User } from '@user/shared/models';

export const TrackingActions = createActionGroup({
  source: 'Tracking',
  events: {
    'Load Tealium Start': emptyProps(),
    'Load Tealium Success': emptyProps(),
    'Load Tealium Failed': emptyProps(),
    'Send Pure Frontend Event': props<Category>(),
    'Send Registration Event': props<User>(),
    'Send Cms Event': props<{
      payload: EventCategory;
      cmsTracking: ContentCategory;
    }>(),
    'Send Job Ad Create Start Event': emptyProps(),
    'Send Open Logo Guidelines Event': emptyProps(),
  },
});
