import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@app/config/app.config';
import { Cart } from '@cart/models/cart.model';
import { selectAbTest } from '@mkp/ab-test/state';
import { Account } from '@mkp/account/data-access';
import { Metadata, MetadataDictionary } from '@mkp/metadata/data-access';
import { Product, getOriginalPrice, removeUnavailableJS24Product } from '@mkp/shared/util-product';
import {
  EcommerceActionType,
  EcommerceCategory,
  EcommercePlatformType,
  EventActionType,
  EventCategory,
  EventCategoryType,
  EventFunnelType,
  EventNameType,
  JobAdCategory,
  MainCategory,
  PromotionCategory,
  getAbTestsCategory,
  getCompanyCategory,
  getEventCategory,
  getPageCategory,
  getTealiumCategory,
  getUserCategory,
} from '@mkp/tracking/feature-tealium';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@user/shared/models';
import { Vacancy } from '@vacancy/models/vacancy.model';
import { AbTest } from 'libs/ab-test/state/src/lib/ab-test.config';
import { BehaviorSubject } from 'rxjs';

const routes = AppConfig.routes;

@Injectable({
  providedIn: 'root',
})
export class TrackingUtilsService {
  private readonly abTestsSource = new BehaviorSubject<AbTest | undefined>(undefined);

  constructor(
    private readonly _router: Router,
    private readonly translateService: TranslateService
  ) {
    inject(Store).select(selectAbTest).subscribe(this.abTestsSource);
  }

  getMainCategory = (
    user: Pick<User, 'email' | 'id' | 'settings'> | null | undefined,
    account: Account | undefined,
    eventName: EventNameType,
    eventCategory: EventCategoryType,
    eventAction: EventActionType
  ): MainCategory => {
    return {
      ...getUserCategory(user, account?.id),
      ...getCompanyCategory(account?.legalEntity),
      ...getPageCategory(user?.settings.language ?? null, this._router.url),
      ...getEventCategory(eventCategory, eventAction, eventName),
      ...getTealiumCategory(eventName),
      ...getAbTestsCategory(this.abTestsSource.value),
    };
  };

  vacancyToJobAdTracking = (vacancy: Vacancy, metadata: MetadataDictionary): JobAdCategory => {
    const today = new Date();
    const daysLeft = 30;
    return {
      job_id: vacancy.id,
      job_title: vacancy.jobPosition,
      job_location: vacancy?.address?.city, // FELDM-111 Only contain city not full address
      job_publication_date: today.toISOString(),
      job_end_date: new Date(today.setDate(today.getDate() + daysLeft)).toISOString(),
      job_days_left: daysLeft,
      job_status: 'n',
      job_employment_grade: `${vacancy.employmentGradeFrom}%-${vacancy.employmentGradeTo}%`,
      job_employment_type: vacancy.metadataApiEmploymentTypeId
        ? metadata['contractType'].find(
            (_metadata: Metadata) =>
              _metadata.id === vacancy.metadataApiEmploymentTypeId?.toString()
          )?.text
        : undefined,
      job_position: vacancy.metadataApiEmploymentPositionId
        ? metadata['seniorityLvl'].find(
            (_metadata: Metadata) =>
              _metadata.id === vacancy.metadataApiEmploymentPositionId?.toString()
          )?.text
        : undefined,
    };
  };

  cartToEcomData = (
    cart: Cart,
    funnelName: EventFunnelType,
    ecommerceAction: EcommerceActionType | undefined
  ): EcommerceCategory => ({
    ecommerce_action: ecommerceAction,
    ecommerce_checkout_funnel_name: funnelName,
    ecommerce_product_id: cart.items.map((item) => `${item.productCode}`),
    ecommerce_product_name: cart.items.map(
      (item) => item.longname && this.translateService.instant(item.longname)
    ),
    ecommerce_product_paid_free: cart.items.map((item) => (item.price ? 'Paid' : 'Free')),
    ecommerce_product_price: cart.items.map((item) => Number(item.price / 100)),
    ecommerce_product_quantity: cart.items.map(() => 1),
    ecommerce_transaction_id: cart.cartTokenValue,
    ecommerce_transaction_total: Number(cart.totals.total / 100), // currently we only support 1 product in cart
  });

  cartToPromotionData = ({
    totals: { promotion, totalWithoutTaxes },
    couponCode,
  }: Cart): PromotionCategory => {
    const round2Digit = (num: number) => Math.abs(num).toFixed(2);

    // we support only one coupon per Cart
    // promotion represents the total discount for complete Cart, not available per item
    // only add prop if it exists (causes issue on tealium)
    return Object.assign(
      {},
      promotion
        ? {
            promotion_discount_chf: [Number(round2Digit(promotion / 100))],
            promotion_discount_percent: [
              `${round2Digit((promotion / (totalWithoutTaxes + Math.abs(promotion))) * 100)}`,
            ],
          }
        : null,
      couponCode ? { promotion_voucher_ID: [couponCode] } : null
    );
  };

  getECommerceCategory = (
    products: Product[],
    funnelName: EventFunnelType,
    platform: EcommercePlatformType | undefined,
    ecommerceAction: EcommerceActionType | undefined
  ): EcommerceCategory => {
    const filteredProducts = removeUnavailableJS24Product(products);
    const productDetails = <T = string>(
      prop: keyof Product,
      mapFunc: CallableFunction | undefined = undefined
    ): T[] =>
      filteredProducts.map((product: Product) =>
        mapFunc ? mapFunc(product[prop]) : (product[prop] as T)
      );

    return {
      ecommerce_action: ecommerceAction,
      ecommerce_checkout_funnel_name: funnelName,
      ecommerce_product_id: productDetails('code'),
      ecommerce_product_name: productDetails('longname', (x: string) =>
        this.translateService.instant(x)
      ),
      ecommerce_product_price: filteredProducts.map(getOriginalPrice),
      ecommerce_product_quantity: filteredProducts.map(() => 1),
      ecommerce_product_list_name: platform,
    };
  };

  getLogoGuidelinesEventCategory = (): Pick<
    EventCategory,
    'event_funnel_name' | 'event_funnel_step'
  > => {
    const isCreationFlow = this._router.url.includes(`${routes.vacancy}/${routes.publish}`);

    return {
      event_funnel_name: isCreationFlow ? 'job_ad_create' : undefined,
      event_funnel_step: isCreationFlow ? 2 : undefined,
    };
  };
}
